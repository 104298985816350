import React from 'react';

const About = () => {
    return (
        <div className='container'>
            <h2 className='mt-5 mb-4 bold'>About</h2>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda.</p>
            <br />
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda.</p>
            <br />
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, asperiores impedit recusandae aut odit nulla magnam delectus similique. Nisi iusto dolor laudantium, accusamus dolorum inventore. Facilis est incidunt asperiores assumenda.</p>

        </div>
    );
}

export default About;
